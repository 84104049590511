import React from 'react';
import '../css/common.css';
import '../css/aboutus.css'
import j3 from '../asset/j3.png';
import j4 from '../asset/j4.png';
import j5 from '../asset/j5.png';
import j6 from '../asset/j6.png';
import j7 from '../asset/j7.png';
import j11 from '../asset/j11.png';


function Process() {
  return (
    <>

<div className='processbanner'>
    <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}} >OEM / ODM PROCESS</div>
</div>
<div className='aboutus'>

  <div className='aboutt'>
    <img className='abou' src={j3} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j4} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j5} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j6} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j7} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j11} />
  </div>

</div>
    
   </>
  );
}

export default Process;