import React from 'react';
import '../css/common.css'
import '../css/aboutus.css'
import j1 from '../asset/j1.png';
import j2 from '../asset/j2.png';
import j8 from '../asset/j8.png';
import j9 from '../asset/j9.png';
import j10 from '../asset/j10.png';

function Aboutus() {
  return (
    <>

<div className='aboutbanner'>
    <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}} >ABOUT US</div>
</div>
<div className='aboutus'>

  <div className='aboutt'>
    <img className='abou' src={j1} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j2} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j8} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j9} />
  </div>
  <div className='aboutt'>
    <img className='abou' src={j10} />
  </div>

</div>
    
   </>
  );
}

export default Aboutus;