import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/oemlist.css';
import n1 from '../../asset/n1.png';
import n2 from '../../asset/n2.png';

function OemList() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = 5; // Number of carousel items

  useEffect(() => {
    const items = document.querySelectorAll('.carousel-item');

    function setSlide(prevIndex, nextIndex) {
      items[prevIndex].classList.remove('active');
      items[nextIndex].classList.add('active');
    }

    const moveRight = () => {
      const prevIndex = currentIndex;
      const newIndex = (currentIndex + 1) % totalItems;
      setSlide(prevIndex, newIndex);
      setCurrentIndex(newIndex);
    };

    const moveLeft = () => {
      const prevIndex = currentIndex;
      const newIndex = (currentIndex - 1 + totalItems) % totalItems;
      setSlide(prevIndex, newIndex);
      setCurrentIndex(newIndex);
    };

    const rightButton = document.getElementById('moveRight');
    const leftButton = document.getElementById('moveLeft');

    rightButton.addEventListener('click', moveRight);
    leftButton.addEventListener('click', moveLeft);

    // Initial setup
    items[currentIndex].classList.add('active');

    // Cleanup event listeners on component unmount
    return () => {
      rightButton.removeEventListener('click', moveRight);
      leftButton.removeEventListener('click', moveLeft);
    };
  }, [currentIndex, totalItems]);

  return (
    <section className='main2'>

      <div className='main2-heading'>
          <h1>OEM PORTFOLIO</h1>
      </div>

      <div className="carousel">
        <div className="carousel__nav">
          <span id="moveLeft" className="carousel__arrow">
            <svg className="carousel__icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
            </svg>
          </span>
          <span id="moveRight" className="carousel__arrow">
            <svg className="carousel__icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
            </svg>
          </span>
        </div>
        <div className="carousel-item carousel-item--1">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">Skincare</h2>
            <h1 className="carousel-item__title">Velvety Hydration Duo</h1>
            <p className="carousel-item__description">Nourishing moisture for soft, supple skin all day long.</p>
            <Link to="/portfolio" className="carousel-item__btn">MOQ 500</Link>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--2">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">Serum</h2>
            <h1 className="carousel-item__title">Amber Glow Elixir</h1>
            <p className="carousel-item__description">A nourishing serum for a luminous, healthy glow.</p>
            <Link to="/portfolio" className="carousel-item__btn">MOQ 550</Link>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--3">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">Skincare</h2>
            <h1 className="carousel-item__title">Luxura Revitalizing Cream</h1>
            <p className="carousel-item__description">Restore moisture and vitality with this premium revitalizing cream.</p>
            <Link to="/portfolio" className="carousel-item__btn">MOQ 500</Link>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--4">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">Serum</h2>
            <h1 className="carousel-item__title">HydraLift Serum</h1>
            <p className="carousel-item__description">A powerful serum designed to lift, hydrate, and refresh your skin.</p>
            <Link to="/portfolio" className="carousel-item__btn">MOQ 600</Link>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--5">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">Skincare</h2>
            <h1 className="carousel-item__title">Radiant Silk Moisturizer</h1>
            <p className="carousel-item__description">A silky-smooth moisturizer that provides long-lasting hydration and radiance.</p>
            <Link to="/portfolio" className="carousel-item__btn">MOQ 500</Link>
          </div>
          <div className="carousel-item__image"></div>
        </div>
      </div>
    </section>
  );
}

export default OemList;