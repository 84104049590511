import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom'; // useHistory if you're using React Router v5
import TypingEffect from '../common/TypingEffect';
import { db, storage } from "../firebase"; 
import { Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';
import '../css/inquiry.css';
import '../css/common.css'

function OemInquiry({ isLoggedIn, userObj }) {

    const inputRefs = useRef([]);

    const [page, setPage] = useState(0);
    const [reset, setReset] = useState(false);
    const navigate = useNavigate(); // useHistory() if using React Router v5
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [product, setProduct] = useState('');
    const [purpose, setPurpose] = useState('');
    const [qty, setQty] = useState('');
    const [price, setPrice] = useState('');
    const [volumeType, setVolumeType] = useState('');
    const [volume, setVolume] = useState('');
    const [ifVolume, setIfVolume] = useState(0);
    const [tartgetCountry, setTargetCountry] = useState('');
    const [conceptFile, setConceptFile] = useState('');
    const [ifFile, setIfFile] = useState(0);
    const [note, setNote] = useState('');

    const userPhoneNumber = userObj ? userObj.phoneNumber : null;

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/oeminquiry'); // history.push('/oeminquiry') if using React Router v5
        }
    }, [isLoggedIn, navigate]); // or [isLoggedIn, history] for React Router v5


    function formatTimestamp(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    

    const handleNextPage = async () => {
        try {

            setLoading(true);

            // 날짜와 고유 ID 생성
            const today = new Date().toISOString().split('T')[0]; // 오늘 날짜 (YYYY-MM-DD 형식)
            const uniqueId = uuidv4(); // 고유 ID 생성

            // 파일을 저장할 경로 지정 및 업로드
            let conceptFileUrl = '';

            if (conceptFile) {
                const conceptFileRef = ref(storage, `inquiry/${today}/${uniqueId}/${conceptFile.name}`);
                await uploadBytes(conceptFileRef, conceptFile);
                conceptFileUrl = await getDownloadURL(conceptFileRef);
            }

            // 현재 시간 포맷팅
            const timestamp = formatTimestamp(new Date());

            // Firestore에 데이터 저장
            await addDoc(collection(db, "inquiry"), {
                userPhoneNumber,
                name,
                product,
                purpose,
                qty,
                price,
                volumeType,
                volume,
                tartgetCountry,
                conceptFileUrl,
                note,
                timestamp
            });

            const templateParams = {
                userPhoneNumber,
                name,
                product,
                purpose,
                qty,
                price,
                volumeType,
                volume,
                tartgetCountry,
                conceptFileUrl,
                note,
                timestamp
            };
            const emailResult = await emailjs.send('service_e6fxwyo', 'template_9jbh0se', templateParams, '-PYXUln711WX82nnA');
            console.log(emailResult.text); // 이메일 전송 성공 로그
            setLoading(false); // 로딩 상태 해제
        } catch (error) {
            console.error('Error occurred:', error); // 에러 로그 출력
        } finally {
                        // 페이지 이동 (이메일 전송 성공 후)
                        setPage(11);
                        setReset(reset => !reset);
        }
    };



        // 수량이 숫자가 아닌 경우 제거
        const handleQtyChange = (event) => {

          const qtyValue = event.target.value;
  
          const filteredQty = qtyValue.replace(/[^0-9]/g, '');
  
          setQty(filteredQty);
      };

          // 예산이 숫자가 아닌 경우 제거
          const handlePriceChange = (event) => {

            const priceValue = event.target.value;
        
            // 숫자와 소수점만 남기고 첫 번째 소수점만 허용
            const filteredPrice = priceValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        
            setPrice(filteredPrice);
        };

            // 용량이 숫자가 아닌 경우 제거
            const handleVolumeChange = (event) => {

                const volumeValue = event.target.value;
            
                // 숫자와 소수점만 남김. 첫 번째 소수점만 허용하도록 처리
                const filteredVolume = volumeValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            
                setVolume(filteredVolume);
            };

                //파일
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setConceptFile(file);
            setPage(9);
            setReset(reset => !reset);
        }
    };

      //값에 콤마
      const valueComma = (feeNum) => {
        return feeNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const Volumes = () => {
        if (ifVolume === 0) {
            return (
                <>
                    <button onClick={() => {setIfVolume(1); setVolumeType('ml');}}>ml</button>
                    <button onClick={() => {setIfVolume(2); setVolumeType('g');}}>g</button>                  
                </>
            );
        } else if (ifVolume === 1) {
            return (
                <>
                          <input
                              ref={(el) => inputRefs.current[5] = el}
                              value={volume}
                              placeholder='ml'
                              onChange={handleVolumeChange}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(6);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
                </>
            )
        } else if (ifVolume === 2) {
            return (
                <>
                        <input
                              ref={(el) => inputRefs.current[6] = el}
                              value={volume}
                              placeholder='g'
                              onChange={handleVolumeChange}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(7);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
                </>
            )
        }
    };

    const FileCheck = () => {
        if (ifFile === 0) {
            return (
                <>
                    <button onClick={() => setIfFile(1)}>Yes</button>
                    <button onClick={() => {setPage(10); setReset(reset => !reset);}}>No</button>                  
                </>
            );
        } else if (ifFile === 1) {
            return (
                <>
                    <label htmlFor="hidden-design-file">
                        <span className="attach" style={{padding:"10px 20px"}}>
                        File attachment +
                        </span>
                    </label>

                    <input
                        style={{display:"none"}}
                        id="hidden-design-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                </>
            );
        }
    };


    const Pages = () => {
        if (page === 0) {
            return (
              <div className="register-form">
              <TypingEffect text={"Hello! Welcome to Darlibaba, your trusted partner for cosmetics OEM production. May I kindly ask for your name?"} typeSpeed={20} reset={reset} />
              <input 
                  spellcheck="false"
                  ref={(el) => inputRefs.current[0] = el}
                  value={name} 
                  onChange={(event) => setName(event.target.value)}
                  onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                          setPage(1);
                          setReset(reset => !reset);
                      }
                  }} 
              />
              </div>
            );
        } else if (page === 1) {
          return (
              <div className="register-form">
                          <TypingEffect text={`${name}, welcome! What type of cosmetic product are you looking to create?`} typeSpeed={20} reset={reset} />
                          <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[1] = el}
                              value={product} 
                              placeholder="ex) skincare, serum.."
                              onChange={(event) => setProduct(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(2);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
        } else if (page === 2) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${product}, great choice! What is the intended use of the cosmetic product?`} typeSpeed={20} reset={reset} />
                          <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[2] = el}
                              value={purpose}
                              placeholder="ex) for sale, for gifting.."
                              onChange={(event) => setPurpose(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(3);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
      } else if (page === 3) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${purpose}, Understood! How many units are you considering?`} typeSpeed={20} reset={reset} />
                          <input
                              pattern="\d+"
                              ref={(el) => inputRefs.current[3] = el}
                              value={qty}
                              placeholder='ex) 3,000'
                              onChange={handleQtyChange}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    if (parseFloat(qty) < 3000) {
                                        alert('The minimum order quantity must be at least 3,000 units.');
                                        return;
                                    }
                                    setPage(4);
                                    setReset(reset => !reset);
                                }
                            }}
                          />
              </div>
          );
      } else if (page === 4) {
          return (
              <div className="register-form">
                  <TypingEffect text={`${valueComma(qty)} units, got it! How much are you considering the unit price to be?`} typeSpeed={20} reset={reset} />
                          <input
                              ref={(el) => inputRefs.current[4] = el}
                              value={price}
                              placeholder='USD'
                              onChange={handlePriceChange}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(5);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
              </div>
          );
      } else if (page === 5) {
        return (
            <div className="register-form">
                <TypingEffect text={`${valueComma(price)} USD, I see. Please provide the volume information of the product.`} typeSpeed={20} reset={reset} />
                {Volumes()}
            </div>
        );
    } else if (page === 6) {
        return (
            <div className="register-form">
                <TypingEffect text={`${valueComma(volume)} ml, then Which country are you targeting?`} typeSpeed={20} reset={reset} />
                <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[7] = el}
                              value={tartgetCountry}
                              placeholder="ex) Russia, Africa.."
                              onChange={(event) => setTargetCountry(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(8);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
            </div>
        );
    } else if (page === 7) {
        return (
            <div className="register-form">
                <TypingEffect text={`${valueComma(volume)} g, then Which country are you targeting?`} typeSpeed={20} reset={reset} />
                <input
                              spellcheck="false"
                              ref={(el) => inputRefs.current[8] = el}
                              value={tartgetCountry}
                              placeholder="ex) Russia, Africa.."
                              onChange={(event) => setTargetCountry(event.target.value)}
                              onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                      setPage(8);
                                      setReset(reset => !reset);
                                  }
                              }} 
                          />
            </div>
        );
    } else if (page === 8) {
        return (
            <div className="register-form">
                <TypingEffect text={`${tartgetCountry}, great! Do you have any references or design files to provide?`} typeSpeed={20} reset={reset} />
                {FileCheck()}
            </div>
        );
    } else if (page === 9) {
        return (
            <div className="register-form">
                <TypingEffect text={`Thank you, this will be very helpful for product development. Lastly, Feel free to enter any questions you have regarding product development.`} typeSpeed={20} reset={reset} />
                        <input
                            spellcheck="false"
                            ref={(el) => inputRefs.current[9] = el}
                            value={note}
                            onChange={(event) => setNote(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleNextPage();
                                    setReset(reset => !reset);
                                }
                            }} 
                        />
            </div>
        );
    } else if (page === 10) {
        return (
            <div className="register-form">
                <TypingEffect text={`It’s alright, we will actively assist you. Lastly, Feel free to enter any questions you have regarding product development.`} typeSpeed={20} reset={reset} />
                        <input
                            spellcheck="false"
                            ref={(el) => inputRefs.current[10] = el}
                            value={note}
                            onChange={(event) => setNote(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleNextPage();
                                    setReset(reset => !reset);
                                }
                            }} 
                        />
            </div>
        );
    } else if (page === 11) {
        return (
            <div className="register-form">
                <TypingEffect text={`Thank you, ${name}! Your inquiry has been received. The expert cosmetic creators at Darlibaba will contact you via WhatsApp shortly.`} typeSpeed={20} reset={reset} />
                <Link to="/"><button>Go to the main</button></Link>
            </div>
        );
    }
      
    }

    return (
        <>
                    {loading && (
                <div className="loading">
                <span></span>
                <span></span>
                <span></span>
                </div>
            )}
        <section className="register">
            <div>{Pages()}</div>
        </section>
        </>
    );
}

export default OemInquiry;