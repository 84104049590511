import React from 'react';
import '../../css/bestseller.css';
import c1 from '../../asset/c1.png';
import c2 from '../../asset/c2.png';
import c3 from '../../asset/c3.png';
import c4 from '../../asset/c4.png';
import c5 from '../../asset/c5.png';
import c6 from '../../asset/c6.png';
import c7 from '../../asset/c7.png';
import c8 from '../../asset/c8.png';
import c9 from '../../asset/c9.png';
import c10 from '../../asset/c10.png';
import c11 from '../../asset/c11.png';
import c12 from '../../asset/c12.png';

function BestSeller() {
    return (
        <section>
            <div className='main3'>
                <div className='main3-inner'>
                    <div className='main3-heading'>
                        <h1>FEATURED PRODUCTS</h1>
                    </div>
                    <div className='main3-grid-container'>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c1}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c2}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c3}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c4}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c5}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c6}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c7}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c8}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c9}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c10}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c11}/>
                        </div>
                        <div className='main3-grid-item'>
                            <img className='main3-item' src={c12}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BestSeller;