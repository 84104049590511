import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, setUpRecaptcha, signInWithPhoneNumber } from '../firebase';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import '../css/navigation.css';

function Navigation() {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');
  const [localPhoneNumber, setLocalPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // 네비 로직 //
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return () => window.removeEventListener('scroll', changeColor);
  }, []);
  // ... //

  // 로그인 유무 조건부 렌더링 //
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe;
  }, []);
  // ... //

    // 모달 상태 변화 감지하여 값 초기화 //
    useEffect(() => {
      if (!isModalOpen) {
        setLocalPhoneNumber('');
        setVerificationCode('');
      }
    }, [isModalOpen]);
    // ... //

  // 휴대폰 발솔 //
  const handleSendCode = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    setUpRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `${countryCode}${localPhoneNumber}`;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
      window.confirmationResult = confirmationResult;
      alert('Verification code sent!');
    } catch (error) {
      console.error('Error during signInWithPhoneNumber', error);
      alert('Failed to send verification code: ' + error.message);
    }finally {
      setLoading(false); // Stop loading
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    const code = verificationCode;
    if (window.confirmationResult) {
      try {
        const result = await window.confirmationResult.confirm(code);
        const user = result.user;
        alert('Phone number verified!');
        console.log(user);
        setIsModalOpen(false); // Hide modal on successful verification
      } catch (error) {
        console.error('Error during code confirmation', error);
        alert('Failed to verify code: ' + error.message);
        window.location.reload(); 
      }finally {
        setLoading(false); // Stop loading
      }
    } else {
      alert('No confirmation result found. Please send the code first.');
      setLoading(false); // Stop loading if no confirmation result found
      window.location.reload(); 
    }
  };


  // 로그아웃 //
  const handleLogout = () => {
    auth.signOut().then(() => {
      setIsLoggedIn(false);
      alert('Logged out successfully');
      navigate('/'); // 루트 경로로 이동
      window.location.reload(); // 페이지 새로고침
    }).catch(error => {
      console.error('Error during sign out', error);
      alert('Failed to log out: ' + error.message);
    });
  };
  // ... //

  // 로그인 모달 //
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // ... //


  return (
<div className="headerbody">
{loading && (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      <div className={color ? 'header header-bg' : 'header'}>
        <div className='header-content'>
          <div className='left-content'>
            <Link to="/"><span>DARLIBABA</span></Link>
          </div>
          <div className='center-content'>
            <Link to="/aboutus"><span>ABOUT US</span></Link>
            <Link to="/process"><span>OEM/ODM PROCESS</span></Link>
            {isLoggedIn ? (
            <Link to="/oeminquiry"><span>INQUIRY</span></Link>
            ) : (
              <Link to="/oeminquirys"><span>INQUIRY</span></Link>
            )
            }
          </div>
          <div className='right-content'>
            {isLoggedIn ? (
                <>
                  <Link to="/#"><span>Mypage</span></Link>
                  <span onClick={handleLogout}>Logout</span>
                </>
              ) : (
                <span onClick={openModal}>Login</span>
              )}
          </div>
          {/* 추가된 햄버거 메뉴 버튼 */}
          <div className='hamburger-menu' onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* 추가된 드롭다운 메뉴 */}
        {isMenuOpen && (
          <div className={`dropdown-menu ${isMenuOpen ? 'show' : ''}`}>
            <Link to="/aboutus" onClick={toggleMenu}><span>ABOUT US</span></Link>
            <Link to="/process" onClick={toggleMenu}><span>OEM/ODM PROCESS</span></Link>
            {isLoggedIn ? (
            <Link to="/oeminquiry" onClick={toggleMenu}><span>INQUIRY</span></Link>
            ) : (
              <Link to="/oeminquirys" onClick={toggleMenu}><span>INQUIRY</span></Link>
            )
            }
            {isLoggedIn ? (
              <>
                <Link to="/mypage" onClick={toggleMenu}><span>Mypage</span></Link>
                <span onClick={() => {handleLogout(); toggleMenu();}}>Logout</span>
              </>
            ) : (
              <span onClick={() => {openModal(); toggleMenu();}}>Login</span>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className='login-modal-overlay' onClick={closeModal}>
          <div className='login-modal' onClick={(e) => e.stopPropagation()}>
            <form>
              <div id="recaptcha-container"></div>
              <div className='login-heading'>SIGN IN</div>
              <div className='phone-area'>
              <PhoneInput
                country={'us'}
                value={countryCode}
                onChange={(value, country) => setCountryCode(`+${country.dialCode}`)}
                placeholder="Select country code"
              />
              <input
                className='phone'
                style={{borderRadius: "0px 5px 5px 0px"}}
                id="phone"
                value={localPhoneNumber}
                onChange={(e) => setLocalPhoneNumber(e.target.value)}
                placeholder="Enter phone number"
              />
              <button id="check" onClick={handleSendCode}>Send</button>
              </div>
              <div className='code'>
              <input
                id="code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter verification code"
              />
              <button id="recheck" onClick={handleVerifyCode}>Confirm</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navigation;