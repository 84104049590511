import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';

import Main from './page/Main';
import Mypage from './page/Mypage';
import OemInquiry from './page/OemInquiry';
import Aboutus from './page/Aboutus';

import './css/common.css'
import Navigation from './common/Navigation';
import Footer from './common/Footer';
import ScrollToTop from './common/ScrollToTop';
import Portfolio from './page/Portfolio';
import Process from './page/Process';
import Term from './page/Term';
import Personal from './page/Personal';
import OemInquirys from './page/OemInquirys';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState(null);

    // 로그인 유무 조건부 렌더링 //
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          setIsLoggedIn(true);
          setUserObj(user); // 로그인된 유저 객체 설정
        } else {
          setIsLoggedIn(false);
          setUserObj(null); // 로그아웃 시 유저 객체 초기화
        }
      });
      return unsubscribe;
    }, []);
    // ... //

  return (
    <Router>
      <ScrollToTop />
      <div>
      <Navigation />
      {isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Main isLoggedIn={isLoggedIn}/>} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/oeminquirys" element={<OemInquirys isLoggedIn={isLoggedIn} />} />
          <Route path="/oeminquiry" element={<OemInquiry isLoggedIn={isLoggedIn} userObj={userObj}/>} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/process" element={<Process />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/term" element={<Term />} />
          <Route path="/personal" element={<Personal />} />
        </Routes>
      ) : (
        <Routes>
        <Route path="/" element={<Main isLoggedIn={isLoggedIn}/>} />
        <Route path="/oeminquirys" element={<OemInquirys isLoggedIn={isLoggedIn} />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/process" element={<Process />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/term" element={<Term />} />
        <Route path="/personal" element={<Personal />} />
      </Routes>
      )}
      <a style={{position:"relative", zIndex:"2"}} href="https://wa.me/821032898828" target="_blank"><div class="whatsapp"></div></a>
      <Footer />
      </div>
    </Router>
  );
}

export default App;