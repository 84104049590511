import React from 'react';
import videobg from '../../asset/bgvideo_1.mp4';
import { Link } from 'react-router-dom';

function Video({isLoggedIn}) {
    return(
        <section className="main">
         <div className="video-container">
        <video className='video' autoPlay loop muted poster="#">
            <source src={videobg} type='video/mp4' />
            <img class="alterbg" src="#" alt="Alternative Image"/>
        </video>
        <div className="overlay"></div>
        </div>
           <h2>Why Our Company Slogan is</h2>
           <h1>"Your Dream Maker?"</h1>
           {isLoggedIn ? (
            <Link to="oeminquiry"><button>Come in and FEEL IT</button></Link>
           ) : (
           <Link to="oeminquirys"><button>Come in and FEEL IT</button></Link>
           )}
       </section>
    );
}

export default Video;
